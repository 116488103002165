<template>
  <div class="ImageList">
    <grid-row>
      <grid-column
        v-for="(image, index) in imageList"
        :key="`image-list-image-${index}`"
        :width="100/columns">
        <span class="MiddleAlignHelper"/>
        <img
          :src="image"
          class="ImageListImage">
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
import GridRow from '@/components/common/GridRow'
import GridColumn from '@/components/common/GridColumn'

export default {
  name: 'image-list',
  components: {
    GridRow,
    GridColumn
  },
  props: {
    imageList: {
      type: Array,
      required: true
    },
    rows: {
      type: Number,
      required: true
    }
  },
  computed: {
    columns() {
      return Math.ceil(this.imageList.length / this.rows)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';
@import "~@/assets/css/_shared_variables.sass";

.ImageList {
  text-align: center;

  @media (min-width: 641px) {
    width: 80%;
    margin: 0 auto;
  }
  @media (min-width: 961px) {
    width: 60%;
    margin: 0 auto;
  }
  /deep/ .GridRow > .GridColumn {
    margin: 0.75rem 0;
    height: 32px;

    @media #{$tablet-down} {
      width: 50% !important;
    }
    .MiddleAlignHelper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    img {
      max-width: 100%;
      vertical-align: middle;

      @media #{$small-only} {
        max-width: 100px;
      }
    }
  }
  .ImageListImage {
    max-height: 34px;
  }
}
</style>
